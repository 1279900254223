import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
// Components
import Layout from "../components/layout"
import Seo from "../components/seo"
import BigTitle from "../components/titles"
import { PrimaryTheme, SecondaryTheme } from "../constants/theme"

const BlogDetailsTemplate = props => {
  const query = useStaticQuery(graphql`
    query GetBlogBySlug($slug: String) {
      contentfulBlog(slug: { eq: $slug }) {
        title
        author
        content {
          raw
        }
        createdAt
      }
    }
  `)
  return (
    <Layout>
      <Seo title={`OGD42 - Blog & Media - ${query.contentfulBlog.title}`} />
      <div className="container">
        <div
          className="row justify-content-center"
          style={{ paddingTop: "3rem" }}
        >
          <div
            className="col-sm-12 col-md-12 col-lg-8 col-xl-8 d-flex "
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BigTitle style={{ marginTop: 120 }}>Blog Details</BigTitle>
            <StyledBlogContainer>
              <h1>{query.contentfulBlog.title}</h1>
              <span>By {query.contentfulBlog.author}</span>
              {documentToReactComponents(
                JSON.parse(query.contentfulBlog.content.raw)
              )}
            </StyledBlogContainer>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const StyledBlogContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: center;
  align-items: center;
  text-align: LEFT;
  span {
    width: 100%;
    text-align: left;
    font-weight: 600;
    font-size: 1.2rem;
    color: ${PrimaryTheme.primaryColor};
    margin-top: 10px;
    margin-bottom: 10px;
  }
  h1 {
    color: ${SecondaryTheme.accent};
    font-weight: bold;
    &:nth-child(1) {
      text-transform: uppercase;
    }
  }
  h2 {
    color: ${PrimaryTheme.text};
    border-bottom: ${PrimaryTheme.secondaryBackground} 1px solid;
    padding-bottom: 10px;
  }

  p {
    color: ${PrimaryTheme.text};
    font-size: 1.2rem;
  }
  ol {
    color: ${PrimaryTheme.text};
    width: 100%;
  }
  ul {
    color: ${PrimaryTheme.text};
    width: 100%;
  }
`
export default BlogDetailsTemplate
